import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { RadioButton } from 'sw-ui';

import SelectProject from '../selectProject';
import SelectDepartment from '../selectDepartment';
import SelectCompany from '../selectCompany';
import AnalyticsRows from '../analyticsRows';

import Suggest from '../../../../components/Suggest';
import EmployeeSuggest from '../../../../components/employee';
import Input from '../../../../components/input';
import TPDetails from '../../../../components/tpDetails';

import {
  isInputInvalid,
  checkIsRussianCitizen,
  checkIsBelarusianCitizen,
  checkIsKaliningradTicket,
  checkIsForeignPassportIdExists,
 } from '../../../../bi/utils/trip';
import { getPassportType, getPassportDueDate } from '../../../../bi/utils/passport';

import { noNumbers } from '../../../../bi/constants/regExp';
import { LIMITS_INTPUT, FIELDS, VOUCHER_TEXT } from '../../../../bi/constants/trips';
import {
  TRAIN_PROVDERS,
  TRAIN_TRANSIT_DOCUMENT_TYPE,
  TRAIN_TRANSIT_DOCUMENT_LABEL,
} from '../../../../bi/constants/train';

import style from '../../styles/form.module.scss';

const PROVIDERS = [{ id: 0, name: 'TeleTrain' }, { id: 1, name: 'Oneliya' }];

class Voucher extends Component {
  static propTypes = {
    propsForVoucher: PropTypes.object.isRequired,
    onSelectCompany: PropTypes.func.isRequired,
    onSelectProject: PropTypes.func.isRequired,
    onSelectEmployee: PropTypes.func.isRequired,
    onEmployeeFocus: PropTypes.func.isRequired,
    onRemoveEmployee: PropTypes.func.isRequired,
    onSelectDepartment: PropTypes.func.isRequired,
    onChangeEmployee: PropTypes.func.isRequired,
    tripService: PropTypes.object.isRequired,
    mandatoryProject: PropTypes.array,
    isEdit: PropTypes.bool,
    analytics: PropTypes.array,
    tripAnalyticsValueIds: PropTypes.array,
    serviceAnalyticsValueIds: PropTypes.array,
    handleSelectAnalytics: PropTypes.func,
    analyticsValidation: PropTypes.object,
    partEditItem: PropTypes.bool,
    train: PropTypes.object,
  }

  static defaultProps = {
    isEdit: false,
    noOrder: false,
    analytics: [],
    mandatoryProject: [],
    tripAnalyticsValueIds: [],
    serviceAnalyticsValueIds: [],
    handleSelectAnalytics: () => {},
    analyticsValidation: {},
    partEditItem: false,
    train: {},
  }

  componentDidUpdate(prevProps) {
    const {
      propsForVoucher: {
        EmployeeId,
        JsonData: {
          Route: {
            StationDepart,
            StationArrive,
          },
          PassportId,
          TransitDocumentType,
        },
          Employee: {
            Code,
            Documents,
        },
      },
      tripService: {
        changeField,
      },
    } = this.props;

    const {
      propsForVoucher: {
        EmployeeId: prevEmployeeId,
        JsonData: {
          Route: {
            StationDepart: prevStationDepart,
            StationArrive: prevStationArrive,
          },
          TransitDocumentType: prevTransitDocumentType,
          PassportId: prevPassportId,
        },
      },
    } = prevProps;

    if (
      StationDepart === prevStationDepart
      && StationArrive === prevStationArrive
      && TransitDocumentType === prevTransitDocumentType
      && EmployeeId === prevEmployeeId
      && PassportId === prevPassportId
  ) {
      return;
    }

    const isKaliningradTicket = checkIsKaliningradTicket(StationDepart, StationArrive);
    const wasKaliningradTicket = checkIsKaliningradTicket(prevStationDepart, prevStationArrive);
    const isChoosenForeignPassport = checkIsRussianCitizen(Code)
      && checkIsForeignPassportIdExists(Documents, PassportId);
    const isDefaultTransitDocument = TransitDocumentType === TRAIN_TRANSIT_DOCUMENT_TYPE.DEFAULT;

    /** Выбор транзитного документа для беларусского гражданина */
    if (checkIsBelarusianCitizen(Code) && isKaliningradTicket && isDefaultTransitDocument) {
      changeField(
        TRAIN_TRANSIT_DOCUMENT_TYPE.PERMISSION,
        `${FIELDS.JSON_DATA}.${FIELDS.TRANSIT_DOCUMENT_TYPE}`
        );
    }

    /** Установка транзитного документа в NO_VALUE при выполнении условий, если билет тразитный */
    if (isKaliningradTicket && isChoosenForeignPassport && isDefaultTransitDocument) {
      changeField(
        TRAIN_TRANSIT_DOCUMENT_TYPE.NO_VALUE,
        `${FIELDS.JSON_DATA}.${FIELDS.TRANSIT_DOCUMENT_TYPE}`
      );
    }

    /** Сброс значения транзитного документа в дефолтное состояние, если билет перестал быть калининградским */
    if (!isKaliningradTicket && wasKaliningradTicket && !isDefaultTransitDocument) {
      changeField(
        TRAIN_TRANSIT_DOCUMENT_TYPE.DEFAULT,
        `${FIELDS.JSON_DATA}.${FIELDS.TRANSIT_DOCUMENT_TYPE}`
      );
    }
  }

  handleChangeTransitDocument = (value, fieldName) => {
    this.props.tripService.changeField(value, `${FIELDS.JSON_DATA}.${fieldName}`);
  };

  handleChangeInput = (e, field, limit) => {
    const { target: { value } } = e;

    const isInvalidSymbol = noNumbers.test(value);

    if (isInputInvalid(value, isInvalidSymbol, limit)) {
      e.preventDefault();
      return;
    }
    this.props.tripService.changeField(value, `${FIELDS.JSON_DATA}.${field}`);
  }

  validationInput = (value, field) => {
    const { tripService, partEditItem } = this.props;
    const isValid = tripService.trainTripFieldValidation(field, value, partEditItem);
    tripService.changeField(isValid, `${FIELDS.VALIDATION}.${field}`);
  }

  renderProject = () => {
    const {
      onSelectProject,
      mandatoryProject,
      propsForVoucher: {
        projects,
        CompanyId,
        ProjectId,
        validation,
      },
    } = this.props;

    return (
      <SelectProject
        disabled={ !CompanyId }
        currentProjectId={ ProjectId }
        validationProject={ validation.ProjectId }
        mandatoryProject={ mandatoryProject }
        projects={ projects }
        onSelect={ onSelectProject }
      />
    );
  };

  renderDepartment = () => {
    const { propsForVoucher: { EmployeeId, Departments, DepartmentId, validation }, onSelectDepartment } = this.props;
    return (
      <SelectDepartment
        departments={ Departments }
        onSelect={ onSelectDepartment }
        validationDepartment={ validation.DepartmentId }
        currentDepartmentId={ DepartmentId }
        currentEmployeeId={ EmployeeId }
      />
    );
  };

  renderSelectPassport = () => {
    const { propsForVoucher: { Employee, JsonData } } = this.props;

    if (!Employee || !Employee.Id) {
      return <span className={ style['no-valid'] }>{VOUCHER_TEXT.HAS_NOT_PASSPORT}</span>;
    }

    const passportHtml = Employee.Documents.map((passport, index) => {
      const passportType = getPassportType(Employee.Code, passport.Type);
      const passportDueDate = getPassportDueDate(passport.DueDate);
      const passportDueDateHtml = passportDueDate ? `до ${passportDueDate}` : '';

      return (
        <option
          key={ `${passport.Id}_${index}` }
          value={ passport.Id }
        >
          { `${passportType} -  ${passport.Number} ${passportDueDateHtml}` }
        </option>
      );
    });

    const passportValue = JsonData.PassportId ? JsonData.PassportId : '';

    return (
      <select
        value={ passportValue }
        onChange={ e => this.handleChangeInput(e, FIELDS.PASSPORT_ID) }
      >
        { passportHtml }
      </select>
    );
  }

  renderCompany = () => {
    const { propsForVoucher: { companies, CompanyId, validation }, onSelectCompany, isEdit } = this.props;

    if (isEdit) {
      return null;
    }
    return (
      <SelectCompany
        currentCompanyId={ CompanyId }
        companies={ companies }
        onSelect={ onSelectCompany }
        validationCompany={ validation.CompanyId }
      />
    );
  };

  renderAnalytics = () => {
    const { analytics, tripAnalyticsValueIds, handleSelectAnalytics, serviceAnalyticsValueIds, analyticsValidation } = this.props;

    return (
      <AnalyticsRows
        analytics={ analytics }
        serviceAnalyticsValueIds={ serviceAnalyticsValueIds }
        tripAnalyticsValueIds={ tripAnalyticsValueIds }
        onSelect={ handleSelectAnalytics }
        validation={ analyticsValidation }
      />
    );
  };

  renderEmployees = () => {
    const {
      propsForVoucher: {
        Employee,
        validation,
        tpDetails,
        employees,
        CompanyId,
        JsonData: {
          Route: {
            StationDepart,
            StationArrive,
          },
        },
      },
      onSelectEmployee,
      onEmployeeFocus,
      onRemoveEmployee,
      onChangeEmployee,
      tripService: { changeField },
    } = this.props;

    return (
      <div className={ style['col-1-2'] }>
        <EmployeeSuggest
          items={ employees }
          selected={ Employee }
          placeholder='Сотрудник'
          onRemove={ onRemoveEmployee }
          onSelect={ ({ Id }) => onSelectEmployee(Id) }
          valid={ validation.Employees }
          onFocus={ onEmployeeFocus }
          onChange={ onChangeEmployee }
          disablePick={ !CompanyId }
          changeFieldTransitDoc={ changeField }
          isKaliningradTicket={ checkIsKaliningradTicket(StationDepart, StationArrive) }
        />
        <TPDetails
          details={ tpDetails }
        />
      </div>
    );
  }

  renderInput = (fieldData, label, limit) => {
    const { propsForVoucher: { validation, JsonData }, partEditItem, train } = this.props;
    const disabled = partEditItem && JSON.parse(train.JsonData)[fieldData];
    const data = disabled ? JSON.parse(train.JsonData)[fieldData] : JsonData[fieldData];

    return (
      <Input
        field={ fieldData }
        value={ data }
        disabled={ disabled }
        label={ label }
        onChange={ (e, field) => this.handleChangeInput(e, field, limit) }
        onBlur={ ({ target: { value } }) => this.validationInput(value, fieldData) }
        valid={ validation[fieldData] }
      />
    );
  }

  renderOrderInput = (fieldData, label, limit) => {
    const { propsForVoucher: { validation, JsonData }, partEditItem, train } = this.props;

    const disabled = partEditItem && JSON.parse(train.JsonData)[fieldData];
    const data = disabled ? JSON.parse(train.JsonData)[fieldData] : JsonData[fieldData];

    return (
      <Input
        field={ fieldData }
        value={ data }
        disabled={ disabled }
        label={ label }
        onChange={ (e, field) => this.handleChangeInput(e, field, limit) }
        onBlur={ ({ target: { value } }) => this.validationInput(value, fieldData) }
        valid={ validation[fieldData] }
      />
    );
  }

  renderSaleOrderInput = (fieldData, label, limit) => {
    const { propsForVoucher: { validation, JsonData }, partEditItem, train } = this.props;

    const disabled = partEditItem && JSON.parse(train.JsonData)[fieldData];
    const data = disabled ? JSON.parse(train.JsonData)[fieldData] : JsonData[fieldData];

    if (JsonData.ProviderName === TRAIN_PROVDERS.TeleTrain) return null;

    return (
      <div className={ style['col-1-4'] }>
        <Input
          field={ fieldData }
          value={ data }
          disabled={ disabled }
          label={ label }
          onChange={ (e, field) => this.handleChangeInput(e, field, limit) }
          onBlur={ ({ target: { value } }) => this.validationInput(value, fieldData) }
          valid={ validation[fieldData] }
        />
      </div>
    );
  }

  renderSelectProvider = (fieldData, label) => {
    const { propsForVoucher: { validation, JsonData }, tripService } = this.props;
    const currentLabel = JsonData[fieldData] || '';

    return (
      <Suggest
        suggests={ PROVIDERS }
        title={ label }
        currentLabel={ currentLabel }
        onSelect={ ({ name }) => tripService.changeField(name, `${FIELDS.JSON_DATA}.${fieldData}`) }
        onBlur={ ({ target: { value } }) => this.validationInput(value, fieldData) }
        valid={ validation[fieldData] }
      />
    );
  }

  renderValidationTransitDocumentText = (validation, TransitDocumentType) => {
    const hasTransitDocument = TransitDocumentType !== TRAIN_TRANSIT_DOCUMENT_TYPE.NO_VALUE;

    if (!validation.TransitDocumentType || hasTransitDocument) {
      return null;
    }

    return (
      <span className={ style['error-msg'] }>
        { validation.TransitDocumentType }
      </span>
    );
  };

  renderTransitDocumentButton = (isPermissionDocument, fieldName) => (
    <RadioButton
      onChange={ () => this.handleChangeTransitDocument(
            TRAIN_TRANSIT_DOCUMENT_TYPE.PERMISSION,
            fieldName
          )
        }
      checked={ isPermissionDocument }
      className={ style.radioButton }
      value={ TRAIN_TRANSIT_DOCUMENT_TYPE.PERMISSION }
    >
      { TRAIN_TRANSIT_DOCUMENT_LABEL.PERMISSION }
    </RadioButton>
  );

  renderRadioButtonTransitDocuments = (fieldName) => {
    const {
      propsForVoucher:
        {
          JsonData,
          validation,
          Employee: {
            Code,
            Documents,
          },
        },
      } = this.props;

    const {
      Route: {
        StationDepart,
        StationArrive,
      },
      ProviderName,
      TransitDocumentType,
      PassportId,
    } = JsonData;

    const isTeleTrain = ProviderName === TRAIN_PROVDERS.TeleTrain;
    const isKaliningradTicket = checkIsKaliningradTicket(StationDepart, StationArrive);
    const isPermissionDocument = TransitDocumentType === TRAIN_TRANSIT_DOCUMENT_TYPE.PERMISSION;
    const isSimplifiedDocument = TransitDocumentType === TRAIN_TRANSIT_DOCUMENT_TYPE.SIMPLIFED;

    if (!isKaliningradTicket || isTeleTrain) {
      return null;
    }

    if (checkIsBelarusianCitizen(Code)) {
      return this.renderTransitDocumentButton(isPermissionDocument, fieldName);
    }

    if (checkIsRussianCitizen(Code)
      && checkIsForeignPassportIdExists(Documents, PassportId)
    ) {
      return (
        <div className={ style.transitDocumentWrapper }>
          <RadioButton
            onChange={ () => this.handleChangeTransitDocument(
                  TRAIN_TRANSIT_DOCUMENT_TYPE.SIMPLIFED,
                  fieldName,
                )
              }
            checked={ isSimplifiedDocument }
            className={ style.radioButton }
            value={ TRAIN_TRANSIT_DOCUMENT_TYPE.SIMPLIFED }
          >
            { TRAIN_TRANSIT_DOCUMENT_LABEL.UPD }
          </RadioButton>
          { this.renderTransitDocumentButton(isPermissionDocument, fieldName) }
          { this.renderValidationTransitDocumentText(validation, TransitDocumentType) }
        </div>
      );
    }
  };

  render() {
    return (
      <div className={ style.row }>
        <h4>{VOUCHER_TEXT.TITLE}</h4>
        <div className={ style.row }>
          {this.renderCompany()}
          {this.renderProject()}
          {this.renderDepartment()}
        </div>
        { this.renderAnalytics() }
        <div className={ style.row }>
          {this.renderEmployees()}
          <div className={ style['col-1-2'] }>
            <div className={ style.select }>
              <div className={ style.wrap }>
                <label>{VOUCHER_TEXT.PASSPORT}</label>
                { this.renderSelectPassport() }
              </div>
            </div>
          </div>
        </div>
        <div className={ style.row }>
          <div className={ style['col-1-4'] }>
            {this.renderInput(FIELDS.TICKET_ID, VOUCHER_TEXT.TICKET, LIMITS_INTPUT.FOURTEEN)}
          </div>
          <div className={ style['col-1-4'] }>
            {this.renderOrderInput(FIELDS.ORDER_ID, VOUCHER_TEXT.ORDER_ID, LIMITS_INTPUT.FOURTEEN)}
          </div>
          {this.renderSaleOrderInput(FIELDS.SALE_ORDER_ID, VOUCHER_TEXT.SALE_ORDER_ID, LIMITS_INTPUT.FOURTEEN)}
          <div className={ `${style['col-1-4']} ${style['padding-left']}` }>
            {this.renderSelectProvider(FIELDS.PROVIDERNAME, VOUCHER_TEXT.PROVIDER)}
          </div>
        </div>
        <div>
          { this.renderRadioButtonTransitDocuments(FIELDS.TRANSIT_DOCUMENT_TYPE) }
        </div>
      </div>
    );
  }
}
export default Voucher;
